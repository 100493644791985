import React from 'react';
import './App.css';

import Landing from './components/landing/container';

const App = () => (
  <Landing />
);

export default App;
