import React from 'react';
import './index.scss';

import tecnimipLogo from '../../../../assets/img/tecnimip_logo.svg';
import tecnimipPhone from '../../../../assets/img/icons/tecnimip_phone.svg';

const Header = () => (
  <div className='Header'>
    <nav className='navbar navbar-light bg-light'>
      <a className='navbar-brand dosis-bold' href='https://tecnimip.cl'>
        <img src={tecnimipLogo} className='d-inline-block align-top img-tecnimip' alt='' />
      </a>
      <a className='navbar-phone' href='tel:+56979424972'>
        <img src={tecnimipPhone} alt='phone-icon' />
      </a>
    </nav>
    <div className='heroImage d-flex align-items-center'>
      <div className='heroTitle'>
        <h1 className='monserrat-bold'> CONTROL DE CALIDAD, </h1>
        <h1 className='monserrat-bold'> MANEJO DE PLAGAS Y </h1>
        <h1 className='monserrat-bold'> SANITIZACIÓN </h1>
        <p className='monserrat-bold'> Una empresa joven pero con experiencia </p>
      </div>
    </div>
  </div>
);

export default Header;
