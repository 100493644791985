import React, { Component } from 'react';

import Header from '../components/header';
import AboutUs from '../components/about-us';
import Services from '../components/services';
import History from '../components/history';
import Team from '../components/team';
import Footer from '../components/footer';

import tecnimicService1 from '../../../assets/img/tecnimipService1.png';
import tecnimicService2 from '../../../assets/img/tecnimipService2.png';
import tecnimicService3 from '../../../assets/img/tecnimipService3.png';
import tecnimicService4 from '../../../assets/img/tecnimipService4.png';

export default class Landing extends Component {
  dataServices = [
    {
      id: 0,
      description: 'Termitas, palomas, avispas, insectos voladores mediantes Trampas UV y desinsectación. Desinsectación (control de arañas, moscas, tijeretas, garrapatas, hormigas y más.)',
      image: tecnimicService1
    },
    {
      id:1,
      description: 'Sanitización de espacios interiores y exteriores con productos autorizados y con registro ISP para contingencia COVID-19.',
      image: tecnimicService2
    },
    {
      id: 2,
      description: 'Si estás interesado en más de un servicio, puedes contratar el Manejo Integrado de Plagas, MIP a un valor más conveniente.',
      image: tecnimicService3
    },
    {
      id: 3,
      description: 'Ofrecemos asesorías a clientes mediante informes, auditorias y capacitaciones.',
      image: tecnimicService4
    }
  ];

  componentDidMount = () => {
    console.log(
    `
      Thanks for visit tecnimip.cl V2.0
      More about devs:
      https://github.com/Shataap
      https://github.com/alanmunozlopez
    `
    );
  }

  render() {
    return (
      <div className='Landing'>
        <Header />
        <AboutUs />
        <Services 
          squareData={this.dataServices}
        />
        <History />
        <Team />
        <Footer />
      </div>
    )
  }
}
