import React from 'react';
import './index.scss';

import tecnimipLogoWhite from '../../../../assets/img/tecnimip_logo_white.svg';

const Footer = () => (
  <div className='Footer'>
    <div className='container'>
      <div className='row footer-items d-flex justify-content-between'>
        <div className='col-12 col-md-4 row d-flex align-items-center'>
          <figure className='col-auto'>
            <img src={tecnimipLogoWhite} alt='' />
          </figure>
        </div>
        <div className='col-12 col-md-8'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <p>
                Av. Apoquindo #6410, Las Condes,
                <br />
                Región Metropolitana, Chile.
              </p>
            </div>
            <div className='col-12 col-md-6'>
              <p>
                <a href="tel:+56979424972"> +56 9 7942 4972 </a>
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <p>
                Lunes a Viernes de 08:30 - 20:00 hrs
              </p>
            </div>
            <div className='col-12 col-md-6'>
              <p>
                <a href="mailto:ventas@tecnimip.cl"> ventas@tecnimip.cl </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='row powered-by d-flex justify-content-end'>
        <div className='col-md-4 col-sm-12'>
          <p> Desarrollado por: </p>
          <p> Gabriel Araya - <a href='mailto:shatap@live.cl'>shatap@live.cl</a></p>
          <p> Alan Muñoz - <a href='mailto:contacto@alanmunozlopez.com'>contacto@alanmunozlopez.com</a></p>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
