import React from 'react';
import './index.scss';

import aboutImage from '../../../../assets/img/about_us_image.png';

const AboutUs = () => (
  <div className='AboutUs container'>
    <div className='row d-flex justify-content-center'>
      <div className='col-sm-12 col-md-6'>
        <h2 className='monserrat-bold'> MANEJO INTEGRADO DE PLAGAS </h2>
        <h3 className='monserrat-bold'> RESIDENCIAS & EMPRESAS </h3>
        <h5 className='monserrat-semi-bold text-left col-sm-12 col-md-10'>Trabajamos con productos y metodologías limpias con la finalidad de no afectar su productividad.</h5>
        <h5 className='monserrat-semi-bold text-left col-sm-12 col-md-10'>Ofrecemos asesorías a clientes mediante informes, auditorias y capacitaciones.</h5> <br></br>
        <h5 className='monserrat-semi-bold text-left col-sm-12 col-md-10'>Sanitización con productos autorizados y registro ISP para contingencia COVID-19</h5>
      </div>
      <div className='col-sm-12 col-md-6 d-flex justify-content-center align-items-end crop'>
        <img src={aboutImage} alt='' />
      </div>
    </div>
  </div>
);

export default AboutUs;
