import React from 'react';

const SquareServices = (props) => (
  <div className='row col-sm-12 col-md-6 d-flex justify-content-center align-content-start service'>
    <figure>
      <img className='col-auto col-md-12' src={props.image} alt='' />
    </figure>
    <p className='col col-md-12 monserrat-medium'> {props.description}</p>
  </div>
);

export default SquareServices;
