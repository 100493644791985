import React from 'react';
import './index.scss';
import SquareServices from './components/square-services';

import tecnimipFormsLogo from '../../../../assets/img/tecnimip_forms_logo.svg';

const Services = (props) => (
  <div className='Services'>
    <div className='container'>
      <h2 className='Monserrat-Bold text-center'> NUESTROS SERVICIOS </h2>
      <div className='col-sm-12 col-md-12 d-flex justify-content-center align-items-center crop'>
        <img src={tecnimipFormsLogo} alt='' />
      </div>
      <div className='row d-flex justify-content-center'>
        {
          props.squareData.map(item =>
            <SquareServices
              key={item.id}
              description={item.description}
              image={item.image}
            />
          )
        }
      </div>
    </div>
  </div>
);

export default Services;
