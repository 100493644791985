import React from 'react';
import './index.scss';

import nanoPhoto from '../../../../assets/img/jose_tecnimip.jpg';

const Team = () => (
  <div className='Team container'>
    <div className='row d-flex align-items-center team-description'>
      <div className='col-12 col-md-5 d-flex justify-content-center'>
        <div className='card-tecnimip'>
          <figure>
            <img src={nanoPhoto}  alt=''/>
          </figure>
        </div>
      </div>
      <div className='col-12 col-md-7'>
        <h1 className='monserrat-bold'> JOSÉ GALLARDO </h1>
        <h4 className='monserrat-bold'> Prevencionista de riesgo y medio ambiente</h4>
        <br/><br/><br/>
        <p className='monserrat-semi-bold text-justify'>
        Con más de 12 años de experiencia en control de plagas, Control de
        calidad y unos de los pioneros en el control de termitas
        subterráneas, con diferentes cursos como control de plagas
        urbanas, manejo de aplicación fosfina (fumigación) cursos en
        gestión de calidad en atención al cliente, curso ISO 9001, curso de
        normas A y B, entre otros.</p>
        <p className='monserrat-semi-bold text-justify'>Buen manejo de grupos de trabajo y excelente relación con sus
        clientes</p>
      </div>
    </div>
  </div>
);

export default Team;
