import React from 'react'

const History = () => (
  <div className='AboutUs container'>
		<div className='row d-flex justify-content-center'>
			<div className='col-sm-2 col-md-5'></div>
			<div className='col-sm-10 col-md-7'>
				<h1 className='monserrat-bold'> NUESTRA HISTORIA  </h1>
				<br/>
				<p className='monserrat-semi-bold text-justify'>Con 12 años en el rubro de plagas urbanas, nos desarrollamos como
					formadores de técnicos y controles de calidad, tenemos experiencia como
					jefes en ambas áreas a nivel nacional. Creemos que la principal fuente de
					reclamo de los clientes siempre es “Calidad de Servicio” donde el principal
					afectado es el cliente.</p>
				<p className='monserrat-semi-bold text-justify'>Entonces cuando descubrimos esto después de pasar por muchas
					experiencias, sabiamos que el cliente se percataba, que estaba la cantidad
					de clientes por encima de la calidad para estos mismos y la resultante de
					esto era un mal rato para el control de calidad y una pésima inversión para el
					cliente.</p>
				<p className='monserrat-semi-bold text-justify'>Es por eso que decidimos crear TECNIMIP y ser una sola persona con quien
					el cliente se pueda entrevistar y hablar para obtener desde informes hasta
					valores del servicio, pasando por calidad, prevención de riesgos y medio
					ambiente</p>
			</div>
    </div>
  </div>
);
    
export default History;
